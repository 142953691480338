import React                from "react";
import BackgroundImage      from "gatsby-background-image";
import './styles.scss'

// so this case it is going to have two classes
// children is the props that is passed between the tags .

const StyledHero = ({ img, children, home }) => {
    return (
        <BackgroundImage className= {home=== "true" ? 'hero-style-home' : 'hero-style'} fluid={img} >
            {children}
        </BackgroundImage>
    )
}

export default StyledHero 



  

